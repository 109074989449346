<template>
    <div>
        <div class="inviteHeader">
            <div v-wechat-title="$route.meta.title"></div>
            <div class="invite_title">邀好友填写您的邀请码获得五一开奖机会</div>
            <img src="../static/image/indeximg/invitation_card@2x.png" class="bj" />
            <div class="inviteHeaderTop">
                <div class="myInviteText">我的邀请码</div>
                <div class="myCode">{{myCode}}</div>
                <div class="copyBtn"  
                     v-clipboard:copy="myCode"
                     v-clipboard:success="onCopy"
                     v-clipboard:error="onError"
                >复制</div>
                <div class="inviteFooter" @click="shareFloat()">邀请好友</div>
            </div>
            
            <!-- <div class="inviteHeaderBottom">邀好友填写您的邀请码获取3号福袋</div> -->
        </div>
        <img src="../static/image/indeximg/invitation_process@2x.png" class="inviteMain" />
        
    </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
import VueClipboard from 'vue-clipboard2'
import utils from '../utils'
Vue.use(VueClipboard)
export default {
    data() {
        return {
            myCode:'112233',
            userId:'',
            token:'',
        };
    },
    created(){
        //获取userId和token
        this.userId = utils.getRequest().userId ? utils.getRequest().userId : "";
        this.token = utils.getRequest().token ? utils.getRequest().token : "";
         //去登录
        if(this.userId == '' || this.token == ''){
            window.location.href = "https://meisheapp.com?command=enterLogin";
            return false
        }

        window.APPHREF =  function (userId, token) {
            window.location.href=`/Index?userId=${userId}&token=${token}`
        }
    },
    async mounted(){
        //邀请码
        let {app_key, date, sign} = utils.getSign('v1/TwelveDay/getInviteCode')
        let {errNo,data,message} = await this.$api.myInviteCode(app_key,date,sign,this.token,this.userId)
        if(errNo == 0){
            this.myCode = data.invite_code
        }
        

    },
    methods: {
         // 复制成功
        onCopy(e){
            Toast.success('复制成功')
        },
        // 复制失败
        onError(e){
            Toast.success('复制失败')
        },
        //分享
        async shareFloat(){
            let data = {
                title: '来自好友的邀请，一起收麦子，领红包',
                desc: "五一在云美摄收麦子赢取现金红包！",
                imgUrl: `${utils.activityUrl}/image/suolvetu1_2x.png`,
                url : `${utils.activityUrl}/wuyiInviteFriend`+encodeURIComponent(`?userId=${this.userId}&token=${this.token}`),
            }
            window.location.href = 'http://meisheapp.com?command=shareWebHtml&url=' + data.url + '&imgUrl=' + data.imgUrl + '&title=' + data.title + '&desc=' + data.desc;
        },
    },
};
</script>
<style scoped>
.inviteHeader {
    width: 375px;
    height: 377px;
    background: #f5574b;
}
.bj {
    width: 323px;
    height: 278px;
    /* position: absolute;
    top: 69px;
    left: 26px; */
    margin: 69px 26px;
}
.invite_title{
    width: 301px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 22px;
    text-align: center;
    position: absolute;
    top: 28px;
    left: 37px;
}
.inviteHeaderTop {
    position: absolute;
    width: 312px;
    height: 185px;
    text-align: center;
    top: 101px;
    left: 31px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 10px;
}
.myInviteText {
    width: 84px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #757575;
    line-height: 22px;
}
.myCode {
    width: 215px;
    height: 62px;
    font-size: 44px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 62px;
    letter-spacing: 1px;
}
.copyBtn {
    width: 74px;
    height: 30px;
    background: #ef6248;
    border-radius: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    margin-top: 10px;
}
/* .inviteHeaderBottom {
    width: 174px;
    height: 44px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ef6248;
    line-height: 22px;
    position: absolute;
    top: 237px;
    left: 105px;
    text-align: center;
} */
.inviteMain {
    display: block;
    width: 345px;
    height: 136px;
    margin: 16px auto 0;
}
.inviteFooter {
    width: 261px;
    height: 46px;
    background: #f5574b;
    border-radius: 4px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    margin: 38px auto;
}
</style>